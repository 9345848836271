<template>
<b-card class="w-100 mb-4" no-body>
    <div class="p-35">
        <div class="d-flex align-items-start">
            <div>
                <h4 class="card-title mb-1">Add Booking</h4>
                <b-card-text class="text-muted">
                </b-card-text>
            </div>
        </div>
    </div>
    <hr class="m-0" />
    <b-card-body>
        <h4 class="card-title">Booking Info</h4>
        
    </b-card-body>
    <div class="p-35 border-top">
        <div class="btn-grp text-right">
            <b-button pill variant="primary" class="px-4">Save</b-button>
            <b-button pill variant="danger" class="px-4">Cancel</b-button>
        </div>
    </div>
</b-card>
</template>

<script>
export default {
    name: "Add_Booking",
    data: () => ({
        selected: null,
    }),
};
</script>
